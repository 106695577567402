<template>
  <div v-loading="loading" element-loading-text="拼命加载中">
    <div class="mb-5 mt-3" v-if="!notask">
      <el-alert class="mt-3" title="数据采集时间: " type="info" :closable="false" show-icon>{{updatedAt}}</el-alert>
      <div class="row">
        <div class="col">
          <div class="text-center pt-4 pb-3 text-white" style="background:#3b579d">
            <p class="h1">{{total}}</p>
            <p>总任务数</p>
          </div>
        </div>
        <div class="col">
          <div class="text-center pt-4 pb-3 text-white" style="background:#1da1f2">
            <p class="h1">{{progress}}%</p>
            <p>项目进度</p>
          </div>
        </div>
        <div class="col">
          <div class="text-center pt-4 pb-3 text-white" style="background:#ea4c89">
            <p class="h1">{{progressCheck}}%</p>
            <p>质检进度</p>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col">
          <section class="bg-white px-2 pt-2">
            <p class="text-secondary font-weight-bold">完成情况</p>
            <ve-pie :data="finishData"></ve-pie>
          </section>
        </div>
        <div class="col">
          <section class="bg-white px-2 pt-2">
            <p class="text-secondary font-weight-bold">质检情况</p>
            <ve-ring :data="checkData"></ve-ring>
          </section>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-6">
          <section class="bg-white px-2 pt-2">
            <p class="text-secondary font-weight-bold">合格情况</p>
            <ve-funnel :data="qualifiedData" :settings="qualifiedSettings"></ve-funnel>
          </section>
        </div>
      </div>
    </div>
    <div class="container" v-else>
      <div class="text-center">
        <img src="@/assets/img/table1.png" alt="table" style="max-width: 400px;width:100%">
        <p class="h4 text-secondary">{{tips}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import VePie from 'v-charts/lib/pie.common'
import VeRing from 'v-charts/lib/ring.common'
import VeFunnel from 'v-charts/lib/funnel.common'
import { projectStatus } from '@/api/report-task'

let vm

export default {
  name: 'table-tasks',
  components: {
    VePie,
    VeRing,
    VeFunnel
  },
  data () {
    this.qualifiedSettings = {
      useDefaultOrder: true
      // filterZero: true
    }
    return {
      num1001: 0,
      notask: true,
      tips: '暂无数据',
      total: 0,
      progress: 0,
      progressCheck: 0,
      finishData: {
        columns: ['type', 'number'],
        rows: [
          { type: '完成任务数', number: 0 },
          { type: '剩余任务数', number: 0 }
        ]
      },
      checkData: {
        columns: ['type', 'number'],
        rows: [
          { type: '合格任务数', number: 0 },
          { type: '未审核任务数', number: 0 },
          { type: '不合格任务数', number: 0 }
        ]
      },
      qualifiedData: {
        columns: ['type', 'number'],
        rows: [
          { type: '合格', number: 0 },
          { type: '一次合格', number: 0 },
          { type: '两次合格', number: 0 },
          { type: '多次合格', number: 0 }
        ]
      },
      updatedAt: null,
      loading: false
    }
  },
  created () {
    vm = this
    vm.getTasks()
  },
  computed: {
    taskID () {
      return this.$route.query.id
    }
  },
  watch: {
    $route (to, from) {
      if (to.fullPath !== from.fullPath) {
        this.getTasks()
      }
    }
  },
  methods: {
    getTasks () {
      if (!this.taskID) return
      this.loading = true
      projectStatus(this.taskID).then(
        res => {
          // console.log(res)
          const { code, data } = res
          if (code === 0) {
            const report = data.report
            vm.total = report.total || 0
            const taskTable = vm.finishData.rows
            // 完成情况
            taskTable[0].number = report.done || 0
            taskTable[1].number = report.todo || 0
            // 项目进度
            vm.progress = (taskTable[0].number / vm.total * 100).toFixed(2)
            // 质检情况
            vm.checkData.rows[0].number = report.qualified || 0
            vm.checkData.rows[1].number = report.notreviewed || 0
            vm.checkData.rows[2].number = report.unqualified || 0
            // 质检进度
            const checkNum = taskTable[0].number - vm.checkData.rows[1].number
            vm.progressCheck = (checkNum / vm.total * 100).toFixed(2)
            // 合格情况
            vm.qualifiedData.rows[0].number = report.qualified || 0
            vm.qualifiedData.rows[1].number = report.oncequalified || 0
            vm.qualifiedData.rows[2].number = report.twicequalified || 0
            vm.qualifiedData.rows[3].number = report.otherqualified || 0
            const time = new Date(data.updatedAt * 1000)
            vm.updatedAt = time.toLocaleString()
            vm.notask = false
            vm.loading = false
          } else if (code === 1001) {
            if (vm.num1001 < 2) {
              vm.num1001++
              setTimeout(() => {
                vm.getDatas()
              }, 20000) // 20s 后重试
            } else {
              vm.notask = true
              vm.tips = '数据获取中，请稍后尝试'
              vm.num1001 = 0
              vm.loading = false
            }
            // 超时，重试 2 次
          } else {
            vm.notask = true
            vm.tips = '暂无数据'
            vm.loading = false
          }
        }
      )
    }
  }
}
</script>
<style>
</style>
